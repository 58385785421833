export default {
  root: '/',
  termsOfUse: '/terms-of-use',
  privacyNotice: '/privacy-notice',
  signUp: '/sign-up',
  login: '/login',
  auth: {
    root: '/auth',
    verify: '/auth/verify',
    linkedin: '/linkedin',
    login: {
      root: '/auth/login',
      email: '/auth/login/email',
      password: '/auth/login/password',
    },
    forgotPassword: {
      root: '/auth/forgot-password',
      email: '/auth/forgot-password/email',
      verify: '/auth/forgot-password/verify-code',
      create: '/auth/forgot-password/create-password',
    },
    signUp: {
      root: '/auth/sign-up',
      create: '/auth/sign-up/create-user',
      editEmail: '/auth/sign-up/edit-email',
      verify: '/auth/sign-up/verify-email',
    },
    buildRequirements: {
      root: '/auth/requirements',
      startMatching: '/auth/requirements/start-matching',
      startMatchingEmbedded: '/auth/requirements/start-matching-embedded',
      termsOfUseEmbedded: '/auth/requirements/start-matching-embedded/terms-of-use',
      privacyNoticeEmbedded: '/auth/requirements/start-matching-embedded/privacy-notice',
      termsOfUse: '/auth/requirements/start-matching/terms-of-use',
      privacyNotice: '/auth/requirements/start-matching/privacy-notice',
      projectGoals: '/auth/requirements/project-goals',
      projectScope: '/auth/requirements/project-scope',
    },
  },
  embedSignUp: {
    root: '/embed-sign-up',
  },
  addNewProject: {
    root: '/add-new-project',
    activate: '/add-new-project/activate',
    deactivate: '/add-new-project/deactivate',
    startMatching: '/add-new-project/start-matching',
    projectGoals: '/add-new-project/project-goals',
    projectScope: '/add-new-project/project-scope',
  },
  startMatching: '/start-matching',
  startMatchingNewProject: '/start-matching/new-project',
  requirements: '/requirements',
  projectGoals: '/requirements/project-goals',
  projectScope: '/requirements/project-scope',
  projectGoalsEdit: '/requirements/project-goals/edit',
  projectScopeEdit: '/requirements/project-scope/edit',
  projectGoalsNewProject: '/requirements/project-goals/new-project',
  projectScopeNewProject: '/requirements/project-scope/new-project',
  requirementsNewProjectRedirect: '/requirements/new-project/redirect',
  vendors: {
    root: '/vendors',
    rootChangeGoals: '/vendors/change-goals',
    rootChangeScope: '/vendors/change-scope',
    view: '/vendors/:view',
    heatMap: '/vendors/heat-map',
    heatMapChangeGoals: '/vendors/heat-map/change-goals',
    heatMapChangeScope: '/vendors/heat-map/change-scope',
    prosAndCons: '/vendors/pros-and-cons',
    prosAndConsChangeGoals: '/vendors/pros-and-cons/change-goals',
    prosAndConsChangeScope: '/vendors/pros-and-cons/change-scope',
    characteristicMatch: '/vendors/characteristic-match',
    characteristicMatchChangeGoals: '/vendors/characteristic-match/change-goals',
    characteristicMatchChangeScope: '/vendors/characteristic-match/change-scope',
    featureMatch: '/vendors/feature-match',
    featureMatchChangeGoals: '/vendors/feature-match/change-goals',
    featureMatchChangeScope: '/vendors/feature-match/change-scope',
    tableView: '/vendors/table-view',
    tableViewChangeGoals: '/vendors/table-view/change-goals',
    tableViewChangeScope: '/vendors/table-view/change-scope',
    sizeMatchAndScalability: '/vendors/size-match-and-scalability',
    sizeMatchAndScalabilityChangeGoals: '/vendors/size-match-and-scalability/change-goals',
    sizeMatchAndScalabilityChangeScope: '/vendors/size-match-and-scalability/change-scope',
    changeGoals: '/change-goals',
    changeScope: '/change-scope',
  },
  advisor: '/advisor',
  scorecard: {
    root: '/scorecard',
    vendor: '/scorecard/:id',
    settings: '/scorecard/settings',
  },
  vendorDetails: '/workspace/vendors/:id',
  vendorPage: '/vendor-page/:id',
  vendorPageRoot: '/vendor-page/',
  processMapping: {
    root: '/process-mapping',
    moduleQuestions: '/process-mapping/:id',
    finalDetails: '/process-mapping/final-details',
    projectOverview: '/process-mapping/overview',
    editModuleQuestions: '/process-mapping/:id/edit',
    editFinalDetails: '/process-mapping/final-details/edit',
  },
  finalized: '/finalized',
  admin: {
    root: '/admin',
    redirect: '/admin/redirect',
  },
  team: '/team',
  invitations: {
    root: '/invite',
    create: '/invite/create-user',
    verify: '/invite/verify-email',
    expired: '/invite/expired',
  },
  documents: {
    root: '/documents',
    folder: '/documents/:id',
  },
  todo: '/to-do',
  workspace: '/workspace',
  scores: '/scores',
  resources: '/resources',
  settings: {
    root: '/settings',
    project: {
      root: '/settings/project',
      changeGoals: '/settings/project/change-goals',
      changeScope: '/settings/project/change-scope',
      transferConfirm: '/settings/project/transfer-ownership-confirm',
      transferSuccess: '/settings/project/transfer-ownership-success',
    },
    profile: {
      root: '/settings/profile',
      termsOfUse: '/settings/profile/terms-of-use',
      privacyNotice: '/settings/profile/privacy-notice',
      editName: '/settings/profile/edit-name',
      editEmail: '/settings/profile/edit-email',
      editPassword: '/settings/profile/edit-password',
    },
  },
} as const;
