import baseAPI, { APIResponse } from '@api';
import { MainFields } from '@types';

const api = baseAPI();
const apiWithoutMutation = baseAPI(true, true);

export type FetchGetFoldersList = MainFields<{
  logo: string;
  documents: number;
  folderType: 'system' | 'custom' | 'default';
  folderId: number | null;
  subfolders: FetchGetFoldersList[];
  ownerLogo: string;
  createdAt: string;
  owner: string;
}>;

export type FetchGetFolderDocuments = MainFields<{
  fileUrl?: string;
  fileExtension: string;
  createdAt: string;
  owner: string;
  ownerLogo: string;
}>;

export type FetchCreateFolderType = {
  folder: {
    name: string;
    logo?: string;
    folderId?: number;
  };
};
export type FetchGetAllFolders = {
  folders: FetchGetFoldersList[];
};

export type FetchCreateDocumentType = {
  name: string;
  file: File;
  folderId?: number;
};

export type FetchDownloadType = {
  name: string;
  file: File;
};

export type FetchUpdateBatch = {
  id: number[];
  folderId: number;
};

//--------------------------------------------------------------------------------------------------
// Get Folder List
//--------------------------------------------------------------------------------------------------

export function fetchGetFoldersList(projectId: number): APIResponse<FetchGetFoldersList[]> {
  return api.get(`/projects/${projectId}/folders`);
}

//--------------------------------------------------------------------------------------------------
// Get Folders
//--------------------------------------------------------------------------------------------------

export function fetchGetAllFolders(projectId: number): APIResponse<FetchGetAllFolders> {
  return api.get(`/projects/${projectId}/folders/list`);
}

//--------------------------------------------------------------------------------------------------
// Get Folder
//--------------------------------------------------------------------------------------------------

export function fetchGetFolder(id: number): APIResponse<FetchGetFoldersList> {
  return api.get(`/folders/${id}`);
}

//--------------------------------------------------------------------------------------------------
// Update Folder
//--------------------------------------------------------------------------------------------------

export function fetchUpdateFolder(id: number, name?: string, folderId?: number | null) {
  return api.put(`/folders/${id}`, { folder: { name: name, folder_id: folderId } });
}

//--------------------------------------------------------------------------------------------------
// Update Folders Batch
//--------------------------------------------------------------------------------------------------

export function fetchUpdateFoldersBatch(projectId: number, folders: Record<number, number>) {
  return api.put(`/projects/${projectId}/folders/batch_update`, folders);
}

//--------------------------------------------------------------------------------------------------
// Update Document
//--------------------------------------------------------------------------------------------------

export function fetchUpdateDocument(name: string, id: number, folderId?: number) {
  return api.put(`/folders/${folderId}/documents/${id}`, {
    document: { name: name, folder_id: folderId },
  });
}

//--------------------------------------------------------------------------------------------------
// Update Documents Batch
//--------------------------------------------------------------------------------------------------

export function fetchUpdateDocumentsBatch(files: Record<number, number>) {
  return api.put(`/documents/batch_update`, files);
}

//--------------------------------------------------------------------------------------------------
// Create New Folder
//--------------------------------------------------------------------------------------------------

export function fetchPostFolder(
  projectId: number,
  { folder: { folderId, name, logo } }: FetchCreateFolderType,
) {
  return api.post(`/projects/${projectId}/folders`, {
    folder_id: folderId,
    name: name,
    logo: logo,
  });
}

//--------------------------------------------------------------------------------------------------
// Create New Document
//--------------------------------------------------------------------------------------------------

export function fetchPostDocument(id: number, { name, file, folderId }: FetchCreateDocumentType) {
  return api.post(
    `/folders/${id}/documents`,
    { document: { name: name, file: file, folder_id: folderId } },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}

//--------------------------------------------------------------------------------------------------
// Get Folder Documents
//--------------------------------------------------------------------------------------------------

export function fetchGetFolderDocuments(id: number): APIResponse<FetchGetFolderDocuments[]> {
  return api.get(`/folders/${id}/documents`);
}

//--------------------------------------------------------------------------------------------------
// Delete Folder Documents
//--------------------------------------------------------------------------------------------------

export function fetchDeleteFolder(id: number) {
  return api.delete(`/folders/${id}`);
}

//--------------------------------------------------------------------------------------------------
// Delete Folders Batch
//--------------------------------------------------------------------------------------------------

export function fetchDeleteFoldersBatch(projectId: number, folderIds: number[]) {
  return api.delete(`/projects/${projectId}/folders/destroy_all`, {
    data: { ids: folderIds },
  });
}

//--------------------------------------------------------------------------------------------------
// Delete File
//--------------------------------------------------------------------------------------------------

export function fetchDeleteFile(folderId: number, id: number) {
  return api.delete(`/folders/${folderId}/documents/${id}`);
}

//--------------------------------------------------------------------------------------------------
// Delete Files Batch
//--------------------------------------------------------------------------------------------------

export function fetchDeleteFilesBatch(documentIds: number[]) {
  return api.delete(`/documents/destroy_all`, { data: { ids: documentIds } });
}

//--------------------------------------------------------------------------------------------------
// Download Folder
//--------------------------------------------------------------------------------------------------

export function fetchDownloadFolder(id: number) {
  return apiWithoutMutation.get(`/folders/${id}/download_all`, { responseType: 'arraybuffer' });
}

//--------------------------------------------------------------------------------------------------
