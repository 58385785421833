import { combineReducers } from 'redux';

import { p, createSlice, createSliceSimple } from '@redux/slices';

import {
  fetchDeleteFile,
  fetchDeleteFolder,
  fetchDownloadFolder,
  fetchGetFolder,
  fetchGetFolderDocuments,
  fetchGetFoldersList,
  fetchGetAllFolders,
  fetchPostDocument,
  fetchPostFolder,
  fetchUpdateFolder,
  fetchUpdateDocument,
  fetchUpdateFoldersBatch,
  fetchUpdateDocumentsBatch,
  fetchDeleteFoldersBatch,
  fetchDeleteFilesBatch,
} from '../@api';

const prefix = 'DOCUMENTS';

export const getFolderList = createSlice(p('GET_FOLDERS_LIST', prefix), fetchGetFoldersList, {
  initialState: [],
});

export const getAllFolders = createSlice(p('GET_FOLDERS', prefix), fetchGetAllFolders, {
  initialState: {},
});

export const getFolder = createSlice(p('GET_FOLDER', prefix), fetchGetFolder, {
  initialState: {},
});

export const getFolderDocuments = createSlice(
  p('GET_FOLDER_DOCUMENTS', prefix),
  fetchGetFolderDocuments,
  {
    initialState: [],
  },
);

export const setNewFolder = createSliceSimple(p('SET_NEW_FOLDER', prefix), fetchPostFolder);

export const updateFolder = createSliceSimple(p('UPDATE_FOLDER', prefix), fetchUpdateFolder);

export const updateFoldersBatch = createSliceSimple(
  p('UPDATE_FOLDERS_BATCH', prefix),
  fetchUpdateFoldersBatch,
);

export const updateDocument = createSliceSimple(p('UPDATE_DOCUMENT', prefix), fetchUpdateDocument);

export const updateDocumentsBatch = createSliceSimple(
  p('UPDATE_DOCUMENTS_BATCH', prefix),
  fetchUpdateDocumentsBatch,
);

export const createNewDocument = createSliceSimple(
  p('CREATE_NEW_DOCUMENT', prefix),
  fetchPostDocument,
);

export const deleteFolder = createSliceSimple(p('DELETE_FOLDER', prefix), fetchDeleteFolder);

export const deleteFile = createSliceSimple(p('DELETE_FILE', prefix), fetchDeleteFile);

export const deleteFoldersBatch = createSliceSimple(
  p('DELETE_FOLDERS_BATCH', prefix),
  fetchDeleteFoldersBatch,
);

export const deleteFilesBatch = createSliceSimple(
  p('DELETE_FILES_BATCH', prefix),
  fetchDeleteFilesBatch,
);

export const downloadFolder = createSliceSimple(p('DOWNLOAD_FOLDER', prefix), fetchDownloadFolder);

export default combineReducers({
  getFolder: getFolder.reducer,
  getAllFolders: getAllFolders.reducer,
  getFolderList: getFolderList.reducer,
  getFolderDocuments: getFolderDocuments.reducer,
});
