import React from 'react';
import MuiImage from 'mui-image';
import { SxProps, Theme } from '@mui/material';

type First<T> = T extends [infer U, ...any[]] ? U : any;
type MuiImageProps = First<Parameters<typeof MuiImage>>;
export interface ImageProps extends Partial<MuiImageProps> {
  sx?: SxProps<Theme>;
  draggable?: 'true' | 'false';
}

const MuiImageTyped = MuiImage as React.FC<ImageProps>;

const Image: React.FC<ImageProps> = ({ width, height, src = '', alt, style, ...other }) => {
  return (
    <MuiImageTyped
      errorIcon={
        <img
          src={src}
          width={width}
          height={height}
          style={{ minWidth: width, minHeight: height }}
          alt={alt}
        />
      }
      duration={250}
      src={src}
      alt={alt}
      width={width}
      height={height}
      style={{ minWidth: width, minHeight: height, ...style }}
      {...other}
    />
  );
};

export default React.memo(Image);
